<script>
import * as easings from "vuetify/es5/services/goto/easing-patterns";

export default {
  name: "GoTo",
  methods: {
    goTo(pos) {
      this.$vuetify.goTo(pos, {
        duration: 1000,
        offset: 0,
        easing: "easeInOutCubic",
        easings: Object.keys(easings),
      });
    },
  },
};
</script>
