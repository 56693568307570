var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',_vm._l((_vm.value),function(address,i){return _c('v-col',{key:i,staticClass:"d-flex",attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"align-self-stretch flex-grow-1",attrs:{"loading":address.editing && _vm.saving}},[_c('v-card-text',{staticClass:"pb-0"},[(address.editing)?[_c('v-form',{ref:"form",refInFor:true},[_c('customer-address',{attrs:{"outlined":"","required":"","cols":"12","md":"6","hide-details":"auto","disabled":_vm.loadingAddress,"loading":_vm.loadingAddress},on:{"update:loading":function($event){_vm.loadingAddress=$event}},model:{value:(_vm.editingAddress),callback:function ($$v) {_vm.editingAddress=$$v},expression:"editingAddress"}})],1)]:[_c('div',{staticClass:"subtitle-1 text--primary"},[_vm._v(" "+_vm._s(address.street)+", "+_vm._s(address.number)+" ‐ "+_vm._s(address.neighbourhood)+" "),(address.additional)?_c('span',{staticClass:"secondary--text text--lighten-3 d-block d-sm-inline-block"},[_c('span',{staticClass:"d-none d-sm-inline-block"},[_vm._v(" — ")]),_vm._v(" "+_vm._s(address.additional)+" ")]):_vm._e()]),_c('div',[_vm._v(" "+_vm._s(address.city.name)+" ‐ "+_vm._s(address.city.state_code || address.city.state)+", "+_vm._s(_vm._f("zipcode")(address.zipcode))+" ")])]],2),_c('v-card-actions',{staticClass:"justify-end"},[(address.editing)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.saveAddress(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Salvar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.cancelAddress(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancelar")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":_vm.isEditing},on:{"click":function($event){return _vm.editAddress(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error","disabled":_vm.isEditing},on:{"click":function($event){return _vm.deleteAddress(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover")])])]],2)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }