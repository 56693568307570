<template>
  <mask-text-field
    mask="##.###-###"
    placeholder="00.000-000"
    :error-messages="errors"
    :label="label"
    :loading="loading"
    :required="required"
    :value="value"
    @blur="$v.value.$touch()"
    @change="changed"
    v-bind="$attrs"
  />
</template>

<script>
import { maxLength, numeric, requiredIf } from "vuelidate/lib/validators";
import MaskTextField from "@/components/MaskTextField";
import ZipcodeService from "@/services/ZipcodeService";

export default {
  name: "ZipcodeField",
  components: {
    MaskTextField,
  },
  props: {
    label: { type: String, default: "CEP" },
    loading: Boolean,
    required: { type: Boolean, default: false },
    value: String,
  },
  validations: {
    value: {
      required: requiredIf("required"),
      numeric,
      maxLength: maxLength(8),
    },
  },
  computed: {
    errors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push("Campo obrigatório");
      (!this.$v.value.numeric || !this.$v.value.maxLength) &&
        errors.push(`Formato inválido`);
      return errors;
    },
  },
  methods: {
    async changed(zipcode) {
      this.$v.value.$touch();
      this.$emit("input", zipcode);

      if (!zipcode) return;

      this.$emit("update:loading", true);

      try {
        const addr = await ZipcodeService.getAddress(zipcode);
        this.$emit("update:address", addr);
      } catch (err) {
        switch (err?.code) {
          case "not_found":
            this.notifyError(`Parece que este ${this.label} não existe! 😕`);
            break;
          default:
            if (err) {
              this.$sentry.captureException(err);
            }
        }
      } finally {
        this.$emit("update:loading", false);
      }
    },
  },
};
</script>
