<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-card-title class="text-h6 font-weight-medium flex-grow-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                exact
                class="mr-2"
                to="/cadastros/clientes"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>

            <span>Voltar para lista</span>
          </v-tooltip>

          <span v-if="!isNew">
            Cliente {{ customer.fiscal_name || customer.name }}
          </span>
          <span v-else>Novo Cliente</span>
        </v-card-title>
      </v-col>

      <v-col cols="12">
        <v-card outlined :loading="saving">
          <v-form ref="form">
            <v-card-title class="section-title">
              Dados de Gerais
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col class="pt-0">
                  <v-radio-group row hide-details v-model="customerType">
                    <v-radio
                      v-show="isNew || customerType === 'person'"
                      label="Pessoa física"
                      value="person"
                    />

                    <v-radio
                      v-show="isNew || customerType === 'company'"
                      label="Pessoa jurídica"
                      value="company"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <cpf-cnpj-field
                    outlined
                    hide-details="auto"
                    :required="isNew"
                    :readonly="!isNew"
                    :disabled="!isNew"
                    :type="customerType"
                    :rules="[notEmptyRule]"
                    v-model="customer.tax_id"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    outlined
                    hide-details="auto"
                    :label="nameLabel"
                    :rules="[notEmptyRule, maxLengthRule(60)]"
                    v-model="customer.name"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <phone-field
                    outlined
                    required
                    hide-details="auto"
                    v-model="customer.phone"
                  />
                </v-col>

                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    type="email"
                    label="E-mail"
                    prepend-inner-icon="mdi-email-outline"
                    placeholder="E-mail do cliente"
                    v-model="customer.email"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <template v-if="customerType === 'company'">
              <v-card-title class="section-title">
                Dados de Fiscais
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      outlined
                      required
                      hide-details="auto"
                      label="Nome fantasia"
                      :rules="[maxLengthRule(60)]"
                      v-model="customer.fiscal_name"
                    />
                  </v-col>

                  <v-col cols="12" sm="7">
                    <state-tax-field
                      outlined
                      required
                      hide-details="auto"
                      v-model="customer.state_tax_number"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </template>

            <v-card-title>
              <span class="section-title">
                Endereços
              </span>

              <span class="caption" v-if="!isAddressEditing">
                <span class="px-1">&dash;</span>
                <a
                  class="text-decoration-underline"
                  style="letter-spacing: normal !important"
                  @click="newAddress"
                  >Novo endereço</a
                >
              </span>
            </v-card-title>

            <v-card-text>
              <address-list
                ref="addressList"
                :tax-id="customer.tax_id"
                :editing.sync="isAddressEditing"
                v-model="addresses"
              />
            </v-card-text>
          </v-form>

          <v-card-actions
            class="py-12 px-sm-8 justify-center justify-sm-space-between"
          >
            <v-btn outlined class="d-none d-sm-flex" @click="$router.back()">
              Voltar
            </v-btn>

            <div>
              <v-tooltip bottom v-if="!isNew">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    tile
                    color="error"
                    class="mx-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteCustomer"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>

                <span>Remover cliente</span>
              </v-tooltip>

              <v-btn
                large
                color="success"
                :loading="saving"
                @click="saveCustomer"
              >
                <v-icon left>mdi-content-save-outline</v-icon>
                Salvar cliente
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          <span>Carregando cliente</span>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { HttpError } from "@/components/Error";
import AddressList from "@/components/Customer/AddressList";
import CpfCnpjField from "@/components/CpfCnpjField";
import GoTo from "@/mixins/GoTo";
import PhoneField from "@/components/PhoneField";
import Rules from "@/mixins/Rules";
import StateTaxField from "@/components/StateTaxField";

export default {
  name: "RecordsCustomerView",
  metaInfo() {
    return {
      title: this.isNew
        ? "Novo cliente"
        : "Cliente " + (this.customer.fiscal_name || this.customer.name),
    };
  },
  mixins: [Rules, GoTo],
  components: {
    AddressList,
    CpfCnpjField,
    PhoneField,
    StateTaxField,
  },
  data: () => ({
    addresses: [],
    customer: {},
    customerType: "person",
    isAddressEditing: false,
    loading: false,
    saving: false,
  }),
  computed: {
    isNew() {
      return !this.customer?.created_at;
    },
    nameLabel() {
      if (this.customerType === "company") return "Razão social";
      return "Nome do cliente";
    },
  },
  methods: {
    validate() {
      const { form } = this.$refs;
      if (form.validate()) {
        return true;
      }

      this.notifyError("Alguns campos obrigatórios não foram preenchidos 😢");
      return false;
    },
    async saveCustomer() {
      this.saving = true;
      if (!this.validate()) {
        this.saving = false;
        return;
      }

      var customer = { ...this.customer };
      customer.addresses = this.addresses
        .filter((address) => !address.id)
        .map((address) => {
          address.city.id = parseInt(address.city.id);
          address.city.state_code =
            address.city.state || address.city.state_code;
          return address;
        });

      try {
        let url = `/v1/customers/${customer.tax_id}`;
        const { data } = await this.$http.put(url, customer);

        if (this.isNew) {
          this.$router.push({
            name: "customer-view",
            params: { id: customer.tax_id },
          });
        } else {
          this.goTo(0);
        }

        this.mapCustomer(data);
        this.notifySuccess("Cliente salvo com sucesso 🎉");
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao salvar o Cliente 😢");
      } finally {
        this.saving = false;
      }
    },
    async deleteCustomer() {
      const name = this.customer.fiscal_name || this.customer.name;
      const msg = `Tem certeza que deseja deletar o Cliente ${name}?`;
      if (!confirm(msg)) {
        return;
      }
      try {
        await this.$http.delete(`/v1/customers/${this.customer.tax_id}`);
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao deletar o Cliente 😢");
      }
      this.$router.push({ path: "/cadastros/clientes" });
    },
    async loadCustomer(id) {
      this.loading = true;
      try {
        const { data } = await this.$http.get(`/v1/customers/${id}`);
        this.mapCustomer(data);
      } catch (err) {
        if (err instanceof HttpError) {
          switch (err.code) {
            case "not_found":
              this.notifyError(`Cliente ${id} não encontrado`);
              this.$router.push({ path: "/cadastros/clientes" });
              break;
            default:
              this.$sentry.captureException(err);
              this.notifyError("Ocorreu um erro ao carregar o Cliente 😢");
          }
        } else {
          console.error(err);
          this.$sentry.captureException(err);
        }
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1);
      }
    },
    mapCustomer(customer) {
      const { tax_id: taxId, addresses } = customer;
      switch (taxId.length) {
        case 11:
          this.customerType = "person";
          break;
        case 14:
          this.customerType = "company";
          break;
      }

      delete customer.addresses;
      this.customer = customer;
      this.addresses = addresses;
    },
    newAddress(event) {
      this.$refs.addressList.newAddress(event);
    },
  },
  mounted() {
    const { id } = this.$route.params;
    if (!id) return;

    setTimeout(() => {
      this.loadCustomer(id);
    }, 300);
  },
};
</script>
