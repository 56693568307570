<template>
  <v-row>
    <v-col :cols="computedCols.zipcode">
      <zipcode-field
        :readonly="readonly"
        :disabled="isFieldDisabled(local.zipcode)"
        :value="local.zipcode"
        :loading.sync="loadingZipcode"
        @input="updateValueKey('zipcode', $event)"
        @update:address="addressUpdated"
        v-bind="$attrs"
      />
    </v-col>

    <v-col :cols="computedCols.street">
      <v-text-field
        ref="street"
        label="Rua"
        prepend-inner-icon="mdi-map-marker-outline"
        :required="!optional"
        :placeholder="streetPlaceholder"
        :readonly="readonly"
        :disabled="isFieldDisabled(local.street)"
        :value="local.street"
        :rules="optional ? [] : [notEmptyRule]"
        @input="updateValueKey('street', $event)"
        v-bind="$attrs"
      />
    </v-col>

    <v-col :cols="computedCols.number">
      <v-text-field
        ref="number"
        required
        label="Número"
        placeholder="000"
        :readonly="readonly"
        :disabled="isFieldDisabled(local.number)"
        :value="local.number"
        @input="updateValueKey('number', $event)"
        v-bind="$attrs"
      />
    </v-col>

    <v-col v-if="!hideAdditional" :cols="computedCols.additional">
      <v-text-field
        ref="additional"
        required
        label="Complemento"
        placeholder="Exemplo: loja 01"
        :readonly="readonly"
        :disabled="isFieldDisabled(local.additional)"
        :value="local.additional"
        @input="updateValueKey('additional', $event)"
        v-bind="$attrs"
      />
    </v-col>

    <v-col :cols="computedCols.city">
      <city-field
        :required="!optional"
        :readonly="readonly"
        :disabled="isFieldDisabled(local.city && local.city.id)"
        :value="local.city"
        :rules="optional ? [] : [notEmptyRule]"
        @input="updateValueKey('city', $event)"
        v-bind="$attrs"
      />
    </v-col>

    <v-col :cols="computedCols.neighbourhood">
      <v-text-field
        ref="neighbourhood"
        label="Bairro"
        :required="!optional"
        :readonly="readonly"
        :disabled="isFieldDisabled(local.neighbourhood)"
        :value="local.neighbourhood"
        :rules="optional ? [] : [notEmptyRule]"
        @input="updateValueKey('neighbourhood', $event)"
        v-bind="$attrs"
      />
    </v-col>
  </v-row>
</template>

<script>
import CityField from "@/components/CityField";
import Rules from "@/mixins/Rules";
import UpdateModel from "@/mixins/UpdateModel";
import ZipcodeField from "@/components/ZipcodeField";

export default {
  name: "NotaFiscalAddress",
  mixins: [UpdateModel, Rules],
  components: {
    ZipcodeField,
    CityField,
  },
  props: {
    value: Object,
    cols: {
      type: [Number, String],
      default: 12,
    },
    optional: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    loading: Boolean,
    hideAdditional: Boolean,
    streetPlaceholder: {
      type: String,
      default: "Endereço",
    },
  },
  data: () => ({
    loadingZipcode: false,
  }),
  computed: {
    local() {
      return this.value || {};
    },
    computedCols() {
      var zipcode, street, number, additional, city, neighbourhood;

      switch (this.cols) {
        case 6:
          zipcode = 4;
          street = 8;
          number = 3;
          additional = 9;
          break;
        default:
          if (this.$vuetify.breakpoint.mdAndUp) {
            zipcode = 2;
            street = this.hideAdditional ? 3 : 4;
            additional = 4;
            neighbourhood = 2;
          } else if (this.$vuetify.breakpoint.smAndUp) {
            zipcode = 3;
            street = 7;
            additional = 6;
            neighbourhood = 3;
          } else {
            zipcode = street = number = additional = city = neighbourhood = 12;
          }
          if (!number) number = 2;
          if (!city) city = 3;
      }

      return {
        zipcode,
        street,
        number,
        additional,
        city,
        neighbourhood,
      };
    },
  },
  watch: {
    loadingZipcode(v) {
      this.$emit("update:loading", v);
    },
  },
  methods: {
    isFieldDisabled(v) {
      if (this.disabled || this.loadingZipcode) return true;
      return this.readonly && !v;
    },
    addressUpdated(address) {
      let data = {
        street: address.street,
        city: {
          ...address.city,
          state: address.city.state_code,
        },
        neighbourhood: address.neighbourhood,
      };
      if (address.number) {
        data.number = address.number;
      }
      if (address.additional && !this.hideAdditional) {
        data.additional = address.additional;
      }

      this.updateValueObject(data);
      // $nextTick didn't work
      setTimeout(() => {
        var elm;

        if (!data.street) {
          elm = "street";
        } else if (!data.neighbourhood) {
          elm = "neighbourhood";
        } else if (!data.number) {
          elm = "number";
        } else if (!data.additional && !this.hideAdditional) {
          elm = "additional";
        }

        elm && this.$refs[elm].focus();
      }, 1);
    },
  },
};
</script>
