<template>
  <v-autocomplete
    hide-no-data
    item-text="name"
    item-value="id"
    no-filter
    return-object
    :items="items"
    :label="label"
    :loading="computedLoading"
    :search-input.sync="search"
    :value="city"
    @input="$emit('input', $event)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title v-html="genFilteredText(item.name)" />
        </v-list-item-content>

        <v-list-item-action>
          <v-chip label small color="primary" v-text="item.state" />
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CityField",
  props: {
    value: Object,
    label: {
      type: String,
      default: "Cidade",
    },
    loading: Boolean,
  },
  data() {
    return {
      _loading: false,
      search: null,
      city: this.value,
      cities: this.value ? [this.value] : [],
      requesting: null,
    };
  },
  computed: {
    computedLoading() {
      return this.loading || this._loading;
    },
    items() {
      return this.cities.map((city) => {
        return {
          id: city.id,
          name: city.name,
          state: city.state_code || city.state,
        };
      });
    },
    ...mapState("auth", ["company"]),
    preferredState() {
      return (
        this.company?.address.city.state ||
        this.company?.address.city.state_code
      );
    },
  },
  watch: {
    value(v) {
      this.cities = [v];
      this.city = v;
    },
    search(v) {
      let cityName = typeof v === "string" ? v : v?.name;
      if (cityName === this.city?.name) {
        return;
      }
      this._loading = true;

      if (this.requesting) {
        clearTimeout(this.requesting);
      }
      if (!v) {
        this._loading = false;
        return;
      }

      this.requesting = setTimeout(() => {
        this.$http
          .get(
            `/v1/cities/BR?q=${cityName}&per_page=500&preferred_state=${this.preferredState}`
          )
          .then((res) => {
            this.cities = res.data.items;
          })
          .catch((err) => {
            this.$sentry.captureException(err);
          })
          .then(() => {
            this._loading = false;
          });
      }, 300);
    },
  },
  methods: {
    genFilteredText(text) {
      text = text || "";

      if (!this.search) return text;

      const { start, middle, end } = this.getMaskedCharacters(text);
      return `${start}${this.genHighlight(middle)}${end}`;
    },
    getMaskedCharacters(text) {
      const searchInput = (this.search || "").toString().toLocaleLowerCase();
      const index = text.toLocaleLowerCase().indexOf(searchInput);

      if (index < 0) return { start: text, middle: "", end: "" };

      const start = text.slice(0, index);
      const middle = text.slice(index, index + searchInput.length);
      const end = text.slice(index + searchInput.length);
      return { start, middle, end };
    },
    genHighlight(text) {
      return `<span class="v-list-item__mask">${text}</span>`;
    },
  },
};
</script>
