<script>
export default {
  name: "UpdateModel",
  methods: {
    // helper methods which updates this.value

    updateValueKey(key, value) {
      this.updateValueObject({ [key]: value });
    },
    updateValueObject(value) {
      const v = this.updateObject(this.value, value);
      this.$emit("input", v);
    },
    addValueItem(value) {
      this.$emit("input", [...this.value, value]);
    },
    updateValueKeyIndex(index, key, value) {
      const v = this.updateObjectKey(this.value[index], key, value);
      this.updateValueIndex(index, v);
    },
    updateValueIndex(index, value) {
      const v = this.updateArrayIndex(this.value, index, value);
      this.$emit("input", v);
    },
    deleteValueIndex(index) {
      const v = this.updateArrayIndex(this.value, index);
      this.$emit("input", v);
    },

    // methods which receive the original value to be updated

    updateObjectKey(orig, key, value) {
      return this.updateObject(orig, { [key]: value });
    },
    updateObject(orig, value) {
      return { ...orig, ...value };
    },
    updateArrayIndex(orig, index, value) {
      let array = [...orig];
      if (value) {
        array.splice(index, 1, value);
      } else {
        array.splice(index, 1);
      }
      return array;
    },
  },
};
</script>
