<template>
  <v-row>
    <v-col
      v-for="(address, i) in value"
      :key="i"
      cols="12"
      md="6"
      class="d-flex"
    >
      <v-card
        class="align-self-stretch flex-grow-1"
        :loading="address.editing && saving"
      >
        <v-card-text class="pb-0">
          <template v-if="address.editing">
            <v-form ref="form">
              <customer-address
                outlined
                required
                cols="12"
                md="6"
                hide-details="auto"
                :disabled="loadingAddress"
                :loading.sync="loadingAddress"
                v-model="editingAddress"
              />
            </v-form>
          </template>

          <template v-else>
            <div class="subtitle-1 text--primary">
              {{ address.street }}, {{ address.number }} &dash;
              {{ address.neighbourhood }}
              <span
                v-if="address.additional"
                class="secondary--text text--lighten-3 d-block d-sm-inline-block"
              >
                <span class="d-none d-sm-inline-block">
                  &mdash;
                </span>
                {{ address.additional }}
              </span>
            </div>
            <div>
              {{ address.city.name }} &dash;
              {{ address.city.state_code || address.city.state }},
              {{ address.zipcode | zipcode }}
            </div>
          </template>
        </v-card-text>

        <v-card-actions class="justify-end">
          <template v-if="address.editing">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="saveAddress(i)"
                >
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </template>

              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelAddress(i)"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>

              <span>Cancelar</span>
            </v-tooltip>
          </template>

          <template v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  :disabled="isEditing"
                  v-bind="attrs"
                  v-on="on"
                  @click="editAddress(i)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  :disabled="isEditing"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteAddress(i)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>

              <span>Remover</span>
            </v-tooltip>
          </template>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FormatterFilter from "@/mixins/FormatterFilter";
import UpdateModel from "@/mixins/UpdateModel";
import CustomerAddress from "@/components/Address";

export default {
  name: "AddressList",
  mixins: [FormatterFilter, UpdateModel],
  components: {
    CustomerAddress,
  },
  props: {
    value: Array,
    taxId: String,
    editing: Boolean,
  },
  data: () => ({
    saving: false,
    loadingAddress: false,
    editingAddress: {},
  }),
  computed: {
    isEditing() {
      for (const address of this.value) {
        if (address.editing == true) return true;
      }
      return false;
    },
  },
  watch: {
    isEditing(v) {
      this.$emit("update:editing", v);
    },
  },
  methods: {
    newAddress() {
      this.addValueItem({ editing: true, city: {} });
    },
    async saveAddress(index) {
      const { form } = this.$refs;
      if (!form[0].validate()) {
        return;
      }

      this.saving = true;

      var address = { ...this.editingAddress };
      delete address.editing;
      address.city.id = parseInt(address.city.id);
      address.city.state_code = address.city.state || address.city.state_code;

      if (address.id) {
        try {
          let url = `/v1/customers/${this.taxId}/addresses/${address.id}`;
          const { data } = await this.$http.put(url, address);
          this.notifySuccess("Endereço salvo com sucesso 🎉");
        } catch (err) {
          this.$sentry.captureException(err);
          this.notifyError("Ocorreu um erro ao salvar o Endereço 😢");
          return;
        } finally {
          this.saving = false;
        }
      }

      this.updateValueIndex(index, address);
      this.$nextTick().then(() => {
        this.editingAddress = {};
      });
    },
    editAddress(index) {
      const address = this.value[index];
      this.editingAddress = address;
      this.$nextTick().then(() => {
        this.updateValueKeyIndex(index, "editing", true);
      });
    },
    cancelAddress(index) {
      const address = this.value[index];
      if (!address.id) {
        this.deleteValueIndex(index);
      } else {
        this.updateValueKeyIndex(index, "editing", false);
      }
      this.editingAddress = {};
    },
    async deleteAddress(index) {
      const address = this.value[index];
      const street = `${address.street}, ${address.number}`;
      const msg = `Tem certeza que deseja deletar o endereço ${street}?`;
      if (!confirm(msg)) {
        return;
      }

      if (this.taxId && address.id) {
        try {
          await this.$http.delete(
            `/v1/customers/${this.taxId}/addresses/${address.id}`
          );
        } catch (err) {
          this.$sentry.captureException(err);
          this.notifyError("Ocorreu um erro ao deletar o Endereço 😢");
          return;
        }
      }

      this.deleteValueIndex(index);
    },
  },
};
</script>
