<template>
  <v-row>
    <v-col cols="12" sm="5">
      <v-select
        label="Indicador de Inscrição Estadual"
        :items="options"
        :value="type"
        v-bind="$attrs"
        @change="typeChanged"
      />
    </v-col>

    <v-col cols="12" sm="7">
      <v-text-field
        v-if="type === 'taxpayer'"
        label="Inscrição Estadual"
        v-model="number"
        v-bind="$attrs"
        @change="$emit('input', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "StateTaxField",
  props: {
    value: String,
  },
  data: () => ({
    type: "",
    number: "",
  }),
  computed: {
    options() {
      return [
        { text: "Contribuinte", value: "taxpayer" },
        { text: "Não contribuinte", value: "non-taxpayer" },
        { text: "Contribuinte isento", value: "exempted" },
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.number = v;
        if (v === undefined || v === null) this.type = "non-taxpayer";
        else if (!v) this.type = "exempted";
        else this.type = "taxpayer";
      },
    },
  },
  methods: {
    typeChanged(v) {
      this.type = v;
      if (v === "taxpayer") return;

      this.$nextTick().then(() => {
        this.$emit("input", v == "non-taxpayer" ? null : "");
      });
    },
  },
};
</script>
